import { toast } from 'react-toastify'
import moment from 'moment'
import { DATE_FORMAT_OBJ } from './constants'
import OrangeIcon from '../assets/orange-gradient-box.svg'
import YellowIcon from '../assets/yellow-gradient-box.svg'
import PurpleIcon from '../assets/purpule-gradient-box.svg'
import MaroonIcon from '../assets/maroon-gradient-box.svg'
import BlueIcon from '../assets/blue-gradient-box.svg'
import PinkIcon from '../assets/pink-gradient-box.svg'
import ReactGA from 'react-ga4'
import { INTERNAL_ASSET_API } from './apiUrls'

export const captureGAEvent = (category, event_name, label, data) => {
  console.log('GA event:', category, ':', event_name, ':', label)

  let event_params = {
    category,
    label,
    ...data,
  }
  ReactGA.event(event_name, event_params)
}

const DateFormat = localStorage.getItem('date_format')

export function isEmpty(value) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  )
}

export function viewsFormatter(num) {
  if (Math.abs(num) < 1000) return Math.sign(num) * Math.abs(num)
  if (Math.abs(num) < 1000000) return Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'K'

  return Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + 'M'
}

export function secondsToHms(d) {
  d = Number(d)
  var h = Math.floor(d / 3600)
  var m = Math.floor((d % 3600) / 60)
  var s = Math.floor((d % 3600) % 60)

  var hDisplay = h > 0 ? h + ' h ' : ''
  var mDisplay = m > 0 ? m + ' min' : ''
  var sDisplay = s > 0 ? s + 's' : ''
  if (m >= 1 || h >= 1) {
    return hDisplay + mDisplay
  }
  return sDisplay
}

export function AdSecondsConvert(d) {
  d = Number(d)
  var m = Math.floor((d % 3600) / 60)
  var s = Math.floor((d % 3600) % 60)

  // var mDisplay = m > 0 ? m + '.' : ''
  // var sDisplay = s > 0 ? s + '' : ''
  var mDisplay = '00.'
  var sDisplay = '00'

  if (m > 0 && m < 10) {
    mDisplay = `0${m}.`
  }
  if (m > 10) {
    mDisplay = `${m}.`
  }

  if (s > 0 && s < 10) {
    sDisplay = `0${s}`
  }
  if (s > 10) {
    sDisplay = `${s}`
  }

  return mDisplay + sDisplay
}

export function unixTimeToHumanReadable(seconds) {
  if (!seconds) {
    return '----'
  }
  let ans = ''

  // Number of days in month
  // in normal year
  let daysOfMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  let monthsArr = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  let currYear,
    daysTillNow,
    extraDays,
    index,
    date,
    month,
    flag = 0

  // Calculate total days unix time T
  daysTillNow = parseInt(seconds / (24 * 60 * 60), 10)
  // extraTime = seconds % (24 * 60 * 60);
  currYear = 1970

  // Calculating current year
  while (daysTillNow >= 365) {
    if (currYear % 400 === 0 || (currYear % 4 === 0 && currYear % 100 !== 0)) {
      daysTillNow -= 366
    } else {
      daysTillNow -= 365
    }
    currYear += 1
  }

  // Updating extradays because it
  // will give days till previous day
  // and we have include current day
  extraDays = daysTillNow + 1

  if (currYear % 400 === 0 || (currYear % 4 === 0 && currYear % 100 !== 0)) flag = 1

  // Calculating MONTH and DATE
  month = 0
  index = 0
  if (flag === 1) {
    while (true) {
      if (index === 1) {
        if (extraDays - 29 < 0) break

        month += 1
        extraDays -= 29
      } else {
        if (extraDays - daysOfMonth[index] < 0) {
          break
        }
        month += 1
        extraDays -= daysOfMonth[index]
      }
      index += 1
    }
  } else {
    while (true) {
      if (extraDays - daysOfMonth[index] < 0) {
        break
      }
      month += 1
      extraDays -= daysOfMonth[index]
      index += 1
    }
  }

  // Current Month
  if (extraDays > 0) {
    month += 1
    date = extraDays
  } else {
    if (month === 2 && flag === 1) date = 29
    else {
      date = daysOfMonth[month - 1]
    }
  }

  ans += date.toString()
  ans += ' '
  ans += monthsArr[month - 1]
  ans += ' '
  ans += currYear.toString()

  // Return the time
  return ans
}

export function raiseToast(type, message) {
  if (type === 'success') {
    toast.success(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  } else if (type === 'error') {
    toast.error(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  } else if (type === 'warn') {
    toast.warn(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  } else if (type === 'info') {
    toast.info(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
}

export const PAGE_SIZE = 20

export const getReleaseDate = (data) => {
  if (data) {
    return moment(new Date(data + 'Z')).format(
      DATE_FORMAT_OBJ[DateFormat] ? DATE_FORMAT_OBJ[DateFormat] : 'DD-MM-yyyy',
    )
  } else {
    return ''
  }
}

export const getReleaseYear = (data) => {
  if (data) {
    return moment(new Date(data + 'Z')).format('yyyy')
  } else {
    return ''
  }
}

export const createSlug = (name = '') => {
  return name.toLowerCase().replace(/[^A-Z0-9]+/gi, '-')
}

export const getImage = (cfDomainUrl, s3Key) => {
  return cfDomainUrl + '/' + s3Key
}

export const getTrailerUrl = (cfDomainUrl, asset_id, quality) => {
  return cfDomainUrl + '/public/trailer/' + asset_id + '/hls/video/' + quality + '_index.m3u8'
}

export const getAudioTrailerUrl = (cfDomainUrl, url) => {
  return cfDomainUrl + '/' + url
}

export const getAdUrl = (cfDomainUrl, asset_id, quality) => {
  return cfDomainUrl + '/public/advertisement/' + asset_id + '/hls/video/' + quality + '_index.m3u8'
}

export const getRandomWithOneExclusion = (lengthOfArray, indexToExclude) => {
  var random = null //an integer

  while (random === null || random === indexToExclude) {
    random = Math.round(Math.random() * (lengthOfArray - 1))
  }
  return random
}

export const convertDuration = (seconds) => {
  if (seconds !== undefined) {
    seconds = parseInt(seconds)
    let minutes = parseInt(seconds / 60)
    if (seconds > 3600) {
      return Math.floor(minutes / 60) + ' hr ' + (minutes % 60) + ' min'
    } else if (seconds > 60) {
      return Math.floor(seconds / 60) + ' min ' + (seconds % 60) + ' sec'
    } else {
      return seconds + 'sec'
    }
  } else {
    return 'N/A'
  }
}

export const convertDurationSmall = (seconds) => {
  if (seconds !== undefined) {
    seconds = parseInt(seconds)
    let minutes = parseInt(seconds / 60)
    if (seconds > 3600) {
      return Math.floor(minutes / 60) + 'h ' + (minutes % 60) + 'm'
    } else if (seconds > 60) {
      return Math.floor(seconds / 60) + 'm ' + (seconds % 60) + 's'
    } else {
      return seconds + 's'
    }
  } else {
    return 'N/A'
  }
}

export const convertLiveTimeDays = (seconds) => {
  const days = Math.floor(seconds / 86400)

  return `${days < 10 ? `0${days}` : `${days}`}`
}
export const convertLiveTimeHours = (seconds) => {
  seconds %= 86400
  const hours = Math.floor(seconds / 3600)

  return `${hours < 10 ? `0${hours}` : `${hours}`}`
}
export const convertLiveMinutes = (seconds) => {
  seconds %= 86400
  seconds %= 3600
  const minutes = Math.floor(seconds / 60)

  return `${minutes < 10 ? `0${minutes}` : `${minutes}`}`
}

export const convertLiveSeconds = (seconds) => {
  seconds %= 86400
  seconds %= 3600
  seconds %= 60

  return `${seconds < 10 ? `0${seconds}` : `${seconds}`}`
}

export const convertLiveTime = (seconds) => {
  const days = Math.floor(seconds / 86400)
  seconds %= 86400
  const hours = Math.floor(seconds / 3600)
  seconds %= 3600
  const minutes = Math.floor(seconds / 60)
  seconds %= 60

  let result = []
  if (days > 0) result.push(`${days}day${days > 1 ? 's' : ''} `)
  if (days > 3) return result.join(' ')
  if (hours > 0) result.push(`${hours}h`)
  if (minutes > 0) result.push(`${minutes}m`)
  if (seconds > 0) result.push(`${seconds}s`)

  return result.join(' ') || '0s'
}

export function secondsLeft(futureDate) {
  const now = new Date()
  const future = new Date(futureDate)
  const diffInSeconds = Math.floor((future - now) / 1000)
  return diffInSeconds > 0 ? diffInSeconds : 0
}

export const convertCardDuration = (seconds) => {
  if (seconds !== undefined) {
    seconds = parseInt(seconds)
    let minutes = parseInt(seconds / 60)
    if (seconds > 3600) {
      return (
        Math.floor(minutes / 60) +
        ':' +
        (minutes % 60 > 9 ? minutes % 60 : `0${minutes % 60}`) +
        ':' +
        (seconds % 60 > 9 ? seconds % 60 : `0${seconds % 60}`)
      )
    } else if (seconds > 60) {
      return Math.floor(seconds / 60) + ':' + (seconds % 60 > 9 ? seconds % 60 : `0${seconds % 60}`)
    } else {
      return '00:' + seconds
    }
  } else {
    return '00:00'
  }
}

export const getRemainingTime = (totalTime, watchedTime) => {
  if (totalTime === undefined || watchedTime === undefined) return ''

  let remainingTimeInSec = parseInt(totalTime - watchedTime)

  if (remainingTimeInSec > 0) {
    let minutes = parseInt(remainingTimeInSec / 60)
    if (remainingTimeInSec > 3600) {
      return Math.floor(minutes / 60) + ' hours ' + (minutes % 60) + ' mins '
    } else if (remainingTimeInSec > 60) {
      return Math.floor(remainingTimeInSec / 60) + ' mins '
    } else {
      return remainingTimeInSec + ' sec '
    }
  } else {
    return ''
  }
}

export const convertDurationLiveComment = (seconds) => {
  if (seconds !== undefined) {
    seconds = parseInt(seconds)
    if (seconds >= 31536000) {
      return `${Math.floor(seconds / 31536000)} ${
        Math.floor(seconds / 31536000) > 1 ? 'Years ago' : 'Year ago'
      }`
    } else if (seconds >= 2592000) {
      return `${Math.floor(seconds / 2592000)} ${
        Math.floor(seconds / 2592000) > 1 ? 'Months ago' : 'Month ago'
      }`
    } else if (seconds >= 86400) {
      return `${Math.floor(seconds / 86400)} ${
        Math.floor(seconds / 86400) > 1 ? 'Days ago' : 'Day ago'
      }`
    } else if (seconds >= 3600) {
      return `${Math.floor(seconds / 3600)} ${
        Math.floor(seconds / 3600) > 1 ? 'Hours ago' : 'Hour ago'
      }`
    } else if (seconds >= 60) {
      return `${Math.floor(seconds / 60)} ${
        Math.floor(seconds / 60) > 1 ? 'Minutes ago' : 'Minute ago'
      }`
    } else {
      return `${seconds} ${seconds > 1 ? 'Seconds ago' : 'Second ago'}`
    }
  } else {
    return 'N/A'
  }
}

export function getCharCodesCount(str) {
  if (!str) {
    return 0
  }
  let charCodeCount = 0

  for (let i = 0; i < str?.length; i++) {
    let code = str.charCodeAt(i)
    charCodeCount += code
  }

  return charCodeCount
}

export const getRandomBgGradient = (name) => {
  const bgImg = [
    YellowIcon,
    OrangeIcon,
    MaroonIcon,
    PurpleIcon,
    PinkIcon,
    BlueIcon,
    YellowIcon,
    PurpleIcon,
    OrangeIcon,
  ]

  let random = getCharCodesCount(name) % 9 || 9

  return bgImg[random]
}

export const convertToLocalTime = (date) => {
  if (!date) return ''

  return new Date(date + 'Z').toLocaleTimeString('en-UK', {
    timeStyle: 'short',
    hour12: false,
  })
}

export const getPaymentGatewayLogo = (gateway_name) => {
  return (
    INTERNAL_ASSET_API +
    '/public/payment-gateway/' +
    gateway_name.toLowerCase().replace(' ', '-').replace('.', '-') +
    '.png'
  )
}

export const create_share_url = () => {
  return window.location.origin + '/share' + window.location.pathname
}
